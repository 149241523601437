import React from 'react'

const Iframe = ({ title, source, width, height }) => {
  if (!source) {
    return <div>iFrame Loading...</div>
  }

  const src = source
  return (
    <iframe
      title={title}
      src={src}
      width={width}
      height={height}
      // frameBorder='0'
      seamless
      scrolling='no'
      style={{
        backgroundColor: `#f9f9f9`,
        border: 'none',
        overflow: 'hidden',
      }}
    ></iframe>
  )
}

export default Iframe
