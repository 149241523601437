import classNames from 'classnames'
import {
  changeLocale,
  injectIntl,
  IntlContextConsumer,
  FormattedMessage,
} from 'gatsby-plugin-intl'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import enFlag from '../assets/images/en.flag.svg'
import deFlag from '../assets/images/de.flag.svg'

class LanguageSwitcher extends Component {
  constructor() {
    super()
    this.state = {
      show: false,
    }
  }

  show = e => {
    this.setState({
      show: !this.state.show,
    })
  }

  onClose = e => {
    this.props.onClose && this.props.onClose(e)
  }

  getImageByCode(code) {
    return code === 'en' ? enFlag : deFlag
  }

  renderLanguageChoice(code, label, currentLocale) {
    return (
      <div
        className='interfaceRow'
        key={code}
        onClose={e => {
          this.onClose(e)
        }}
      >
        <button
          key={code}
          className={classNames('languageButton', {
            active: currentLocale === code,
          })}
          onClick={e => {
            changeLocale(code)
            this.show(e)
          }}
        >
          {label}
          <img
            src={this.getImageByCode(code)}
            alt={label}
            width='24px'
            style={{
              marginLeft: '0.5rem',
            }}
          />
        </button>
      </div>
    )
  }

  render() {
    const languageNames = [
      { code: 'en', label: 'English' },
      { code: 'de', label: 'German' },
    ]

    return (
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => (
          <>
            <Helmet>
              <html lang={currentLocale} />
            </Helmet>
            <button
              className='popper'
              id='langDropdown'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
              onClick={e => {
                this.show()
              }}
            >
              <FormattedMessage id='navigation.language' />
              <img
                src={this.getImageByCode(currentLocale)}
                alt='Language'
                width='24px'
                style={{
                  marginLeft: '0.5rem',
                }}
              />
            </button>
            {this.state.show ? (
              <div
                className='dropdown'
                aria-labelledby='langDropdown'
                // show={this.state.show}
              >
                {languageNames.map(l =>
                  this.renderLanguageChoice(l.code, l.label, currentLocale)
                )}
              </div>
            ) : null}
          </>
        )}
      </IntlContextConsumer>
    )
  }
}

export default injectIntl(LanguageSwitcher)
