import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import NavItems from './nav'
import Switch from './switch'
import logo from '../../assets/images/v-icon.svg'

const Header = ({ siteTitle }) => (
  <header>
    <div className='logo'>
      <Link to='/'>
        <img className='v-icon' src={logo} alt='Vitapoint Icon' />
      </Link>
    </div>
    <NavItems />
    <Switch />
  </header>
)

export default Header
