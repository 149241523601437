// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-air-values-js": () => import("./../../../src/pages/air-values.js" /* webpackChunkName: "component---src-pages-air-values-js" */),
  "component---src-pages-filter-status-js": () => import("./../../../src/pages/filter-status.js" /* webpackChunkName: "component---src-pages-filter-status-js" */),
  "component---src-pages-hostname-ip-js": () => import("./../../../src/pages/hostname-ip.js" /* webpackChunkName: "component---src-pages-hostname-ip-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-io-scaling-js": () => import("./../../../src/pages/io-scaling.js" /* webpackChunkName: "component---src-pages-io-scaling-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-manual-operation-js": () => import("./../../../src/pages/manual-operation.js" /* webpackChunkName: "component---src-pages-manual-operation-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-operating-hours-js": () => import("./../../../src/pages/operating-hours.js" /* webpackChunkName: "component---src-pages-operating-hours-js" */),
  "component---src-pages-operation-js": () => import("./../../../src/pages/operation.js" /* webpackChunkName: "component---src-pages-operation-js" */),
  "component---src-pages-remote-support-js": () => import("./../../../src/pages/remote-support.js" /* webpackChunkName: "component---src-pages-remote-support-js" */),
  "component---src-pages-scheduler-js": () => import("./../../../src/pages/scheduler.js" /* webpackChunkName: "component---src-pages-scheduler-js" */),
  "component---src-pages-scheduler-monday-js": () => import("./../../../src/pages/scheduler/monday.js" /* webpackChunkName: "component---src-pages-scheduler-monday-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */)
}

