import React from 'react'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import LanguageSwitcher from '../languageSwitcher'

const NavItems = () => {
  return (
    <nav>
      <ul className='nav'>
        <li>
          <Link to='/operation/'>
            <FormattedMessage id='navigation.operation' />
          </Link>
        </li>
        <li>
          <Link to='/air-values/'>
            <FormattedMessage id='navigation.airValues' />
          </Link>
        </li>
        <li>
          <Link to='/media/'>
            <FormattedMessage id='navigation.media' />
          </Link>
        </li>
        <li>
          <Link to='/service/'>
            <FormattedMessage id='navigation.service' />
          </Link>
        </li>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>
    </nav>
  )
}

export default NavItems
