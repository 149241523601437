import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import Iframe from './iframe'
import Header from './header'
import '../assets/css/styles.scss'

// import tempAd from '../assets/images/ad.jpg'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div
        style={{
          height: '640px',
        }}
      >
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
      </div>
      <Iframe
        width='1080px'
        height='1280px'
        source={process.env.GATSBY_IFRAME_URL}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
