import React, { Component } from 'react'
import axios from '../axios'

import powerNull from '../../assets/images/power-icon-blck.svg'
import powerOn from '../../assets/images/power-icon-grn.svg'
import powerOff from '../../assets/images/power-icon-rd.svg'

class Switch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      power: this.getPowerStatus,
      // power: false,
      powerIcon: powerNull,
    }
  }

  changeIcon = state => {
    state
      ? this.setState({ powerIcon: powerOn })
      : this.setState({ powerIcon: powerOff })
  }

  getPowerStatus() {
    axios
      .get('/get/power_button', {})
      .then(res => {
        console.log('get power status: ' + res.data.power)
        this.setState({
          power: !this.state.power,
        })
        this.changeIcon(res.data.power)
        return res.data.power
      })
      .catch(e => {
        console.error(e)
      })
  }

  flipPower = () => {
    const powerState = this.state.power
    axios
      .post('/post/power_button', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          power: powerState,
        },
      })
      .then(() => {
        console.log(this.state.power)
        this.changeIcon(this.state.power)
      })
      .catch(e => {
        console.error(e)
      })
  }

  flipSwitch = () => {
    this.setState({
      power: !this.state.power,
    })
    this.flipPower()
  }

  componentDidMount() {
    // this.getPowerStatus()
    this.interval = setInterval(() => this.getPowerStatus(), 1000)
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    return (
      <div className='switch'>
        <button onClick={this.flipSwitch} className='powerButton'>
          <img src={this.state.powerIcon} alt='Power Icon' />
        </button>
      </div>
    )
  }
}

export default Switch
